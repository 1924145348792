import Icon from "./images/unimed/icon.svg";
import Background from "./images/unimed/background.svg";
import Loading from "./images/unimed/loading.svg";

const PRIMARY_COLOR = "#00955B";
const SECONDARY_COLOR = "#00955B"
const BORDER_COLOR = "#D9D9D9";
const WHITE_COLOR = "#FFF";
const SECONDARY_WHITE_COLOR = "#F5F5F5";
const TEXT_COLOR = "#3A444A";
const PLACEHOLDER_COLOR = "rgba(58,68,74,0.20)";

export default {
  background: Background,
  primaryColor: PRIMARY_COLOR,
  loading: Loading,
  icon: {
    src: Icon,
    height: 106,
    width: 186
  },
  header: {
    background: WHITE_COLOR,
    shadow: "0 2px 4px 0 #DFE3E2",
    title_color: "#272A33",
    subtitle_color: "rgba(39,42,51,0.67)"
  },
  sender: {
    background: WHITE_COLOR,
    shadow: "0 13px 20px 0 rgba(65,70,74,0.17)",
    height: 68
  },
  input: {
    border: `1px solid ${BORDER_COLOR}`,
    background: SECONDARY_WHITE_COLOR,
    text_color: TEXT_COLOR,
    placeholder_color: PLACEHOLDER_COLOR,
  },
  button: {
    background: `linear-gradient(45deg, ${PRIMARY_COLOR} 0%, ${SECONDARY_COLOR} 100%)`,
    shadow: "0 8px 25px 0 rgba(255,255,255,0.37)",
  },
  score: {

  },
  message: {
    from: {
      background: WHITE_COLOR,
      border: "1px solid #EFEFF4",
      shadow: "0 4px 3px 1px rgba(0,0,0,0.05)",
      text_color: "#030303",
      hour_color: "#9092A5"
    },
    to: {
      background: `linear-gradient(45deg, ${PRIMARY_COLOR} 0%, ${SECONDARY_COLOR} 100%)`,
      border: "1px solid #EFEFF4",
      shadow: "0 3px 8px 0 rgba(255,255,255,0.37)",
      text_color: WHITE_COLOR,
      hour_color: WHITE_COLOR,
    }
  },
}