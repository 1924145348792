import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import ReactLoading from 'react-loading';
import Chat from "./containers/Chat";
import { ThemeProvider } from "./themes";
import GlobalStyle from './global-styles';
import { CompanySelectors, CompanyActions } from "./redux/ducks/CompanyRedux";
import UnimedTheme from './themes/unimed';
import _ from 'lodash';


function App() {

  const urlParams = new URLSearchParams(window.location.search);
  const company_param = urlParams.get('company');
  const place_param = urlParams.get('place');

  const dispatch = useDispatch();
  const company = useSelector(CompanySelectors.selectCompany);
  const place = useSelector(CompanySelectors.selectPlace);
  const theme = useSelector(CompanySelectors.selectTheme);

  useEffect(() => {
    console.log({ company_param, place_param })
    dispatch(CompanyActions.getCompany(company_param, place_param))
  }, [dispatch, company_param, place_param])

  useEffect(() => {
    console.log("SLUGS", company, place)
  }, [company, place])


  if (_.isEmpty(company)) {
    return (
      <div style={{ width: '100vw', height: '100vh', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
        <ReactLoading type={'bubbles'} color={"#00B6E3"} width={150} />
      </div>
    );
  }


  return (
    <ThemeProvider theme={UnimedTheme}>
      <Chat />
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
