import { createGlobalStyle } from 'styled-components';
import { Colors } from './themes';
import SFCompactTextBold from './themes/fonts/SF-Compact-Text-Bold.otf';
import SFCompactTextBoldItalic from './themes/fonts/SF-Compact-Text-BoldItalic.otf';
import SFCompactTextHeavy from './themes/fonts/SF-Compact-Text-Heavy.otf';
import SFCompactTextHeavyItalic from './themes/fonts/SF-Compact-Text-HeavyItalic.otf';
import SFCompactTextItalic from './themes/fonts/SF-Compact-Text-Italic.otf';
import SFCompactTextLight from './themes/fonts/SF-Compact-Text-Light.otf';
import SFCompactTextLightItalic from './themes/fonts/SF-Compact-Text-LightItalic.otf';
import SFCompactTextMedium from './themes/fonts/SF-Compact-Text-Medium.otf';
import SFCompactTextMediumItalic from './themes/fonts/SF-Compact-Text-MediumItalic.otf';
import SFCompactTextRegular from './themes/fonts/SF-Compact-Text-Regular.otf';

const GlobalStyle = createGlobalStyle`

    
    @font-face {
        font-family: 'SFCompactText-Bold';
        src: url(${SFCompactTextBold})  format('opentype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SFCompactText-BoldItalic';
        src: url(${SFCompactTextBoldItalic})  format('opentype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SFCompactText-Heavy';
        src: url(${SFCompactTextHeavy})  format('opentype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SFCompactText-HeavyItalic';
        src: url(${SFCompactTextHeavyItalic})  format('opentype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SFCompactText-Italic';
        src: url(${SFCompactTextItalic})  format('opentype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SFCompactText-Light';
        src: url(${SFCompactTextLight})  format('opentype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SFCompactText-LightItalic';
        src: url(${SFCompactTextLightItalic})  format('opentype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SFCompactText-Medium';
        src: url(${SFCompactTextMedium})  format('opentype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SFCompactText-MediumItalic';
        src: url(${SFCompactTextMediumItalic})  format('opentype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SFCompactText-Regular';
        src: url(${SFCompactTextRegular})  format('opentype');
        font-weight: normal;
        font-style: normal;
    }

  html,
  body,
  #root {
    height: 100%;
    width: 100%;
    margin: 0;
  }

  * {
    font-family: 'SF-Compact-Text-Regular', Helvetica, Arial, sans-serif;
    outline: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased !important;
  }

  body.fontLoaded {
    font-family: 'SF-Compact-Text-Regular', Helvetica, Arial, sans-serif;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
  }
`;

export default GlobalStyle;