import styled from "styled-components";
import { withTheme } from "../../themes";

export const MessageContainer = styled.div`
  display: flex;
  justify-content: ${props => props.to ? "flex-end" : "flex-start"};
`;

export const MessageWrapper = withTheme(styled.div`
  background: ${props => (props.to ? props.theme.message.to.background : props.theme.message.from.background )};
  border: ${props => (props.to ? props.theme.message.to.border : props.theme.message.from.border )};
  box-shadow: ${props => (props.to ? props.theme.message.to.shadow : props.theme.message.from.shadow )};
  color: ${props => (props.to ? props.theme.message.to.text_color : props.theme.message.from.text_color )};
  border-radius: 8px 8px ${props => props.to ? 0 : 8}px ${props => props.to ? 8 : 0}px;
  padding: 10px;
  font-family: SFCompactText-Regular;
  font-size: 16px;
  margin: 10px 20px;
  max-width: 60%;
`)

export const HourWrapper = withTheme(styled.span`
  font-family: SFCompactText-Regular;
  font-size: 10px;
  color: ${props => (props.to ? props.theme.message.to.hour_color : props.theme.message.from.hour_color )};;
  letter-spacing: 0;
  text-align: left;
`)

export const Star = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 8px;
`

export const LoadingWrapper = styled.div`
  width: 65px;
  height: 19px;
  position: relative;
`
export const Loading = styled.img`
  width: 80px;
  position: absolute;
  top: -30px;
  left: -8px;
`