import React from "react";
import * as S from "./styled";

const Options = ({ options, onSubmit }) => {
  return (
    <S.OptionsWrapper>
      {options.map(((option, index) => 
        <S.Option key={option} index={index} onClick={() => onSubmit(option)}>
          {option}
        </S.Option>
      ))}
    </S.OptionsWrapper>
  );
}

export default Options