import styled from "styled-components";
import { withTheme } from "../../themes";

export const Wrapper = withTheme(styled.div`
  background: ${props => props.theme.header.background};
  box-shadow: ${props => props.theme.header.shadow};
  height: 80px;
  display: flex;
  align-items: center;
  z-index: 1;
`)

export const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Title = withTheme(styled.h1`
  font-family: SFCompactText-Bold;
  font-size: 24px;
  color: ${props => props.theme.header.title_color};
  margin: 0;
`)

export const Subtitle = withTheme(styled.h2`
  font-family: SFCompactText-Light;
  font-size: 13px;
  color: ${props => props.theme.header.subtitle_color};
  margin: 0;
`)

export const Icon = styled.img`
  padding: 0 16px;
`