import React, { useRef } from "react";
import * as S from "./styled";

import emoticon from "../../images/icons/emoticon.svg";
import check from "../../images/icons/check.svg";

const Input = ({ disabled, onSubmit, type }) => {
  const inputRef = useRef()
  let extra_props = {}
  if(type === "number"){
    extra_props = {type: "number", min: "0", inputmode: "numeric", pattern:"[0-9]*"}
  }else{
    extra_props = { type }
  }

  const submit = () => {
    onSubmit(inputRef.current.value)
    inputRef.current.value = "";
    inputRef.current.blur();
  }


  return (
    <S.Wrapper disabled={disabled}>
      <S.Emoticon src={emoticon}/>
      <S.Input {...extra_props} ref={inputRef} placeholder="Digite aqui..." disabled={disabled} onKeyUp={(e) => {
        if(e.keyCode === 13){
          submit()
        }
      }}/>
      <S.SendButton onClick={submit}>
        <S.Check src={check}/>
      </S.SendButton>
    </S.Wrapper>
  );
}

export default Input;