import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-self: center;
  margin: 40px 0;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.color};
  font-family: "SFCompactText-Bold";
  font-size: 18px;
  color: #fff;
  border-radius: 6px;
  border: none;
  width: 28px;
  height: 31px;
  margin-right: 3px;

  :hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 768px) {
    width: 28px;
    height: 31px;
    font-size: 16px;
  }
`;

const COLORS = [
  "#BD2B00",
  "#EE3700",
  "#F34625",
  "#F98E00",
  "#F88C00",
  "#FAC200",
  "#F0C100",
  "#A9C900",
  "#6FC91F",
  "#3DC007",
  "#30980A"
];

export default ({ onSubmit }) => {
  return (
    <Wrapper>
      {COLORS.map((color, index) => (
        <Button onClick={() => onSubmit(index)} key={index} color={color}>
          {index}
        </Button>
      ))}
    </Wrapper>
  );
};
