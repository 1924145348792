
import { call, put } from 'redux-saga/effects'
import { path } from 'ramda'
import { CompanyActions } from '../ducks/CompanyRedux'

export function* company(api, { company_slug, place_slug }) {
  try {
    const response = yield call(api.company, company_slug, place_slug);
    const company = path(["data", "company"], response);
    const place = path(["data", "place"], response);
    const theme = path(["data", "theme"], response);


    yield put(CompanyActions.getCompanySuccess(company, place, company_slug, place_slug, theme))
  }catch(e) {
    yield put(CompanyActions.getCompanyFail(e))
  }
  
}