import styled from "styled-components";
import { withTheme } from "../../themes";

export const ChatWrapper = styled.div`
  /* height: calc(100vh - 56px);; */
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  transition: 1s;
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
  -webkit-overflow-scrolling: touch;
`;

export const MessagesWrapper = withTheme(styled.div`
  flex: 1;
  background-image: url(${props => props.theme.background});
  background-repeat: repeat;
  position: relative;
  height: 100%;
  overflow: scroll;
`);

export const MessagesContainer = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`);
