import React from "react";
import { useTheme } from "../../themes";
import { 
  parseISO,
  format
} from 'date-fns';
import { pt } from 'date-fns/locale';
import * as S from "./styled";

import StarFilled from "../../images/icons/star_filled.svg";
import StarEmpty from "../../images/icons/star_empty.svg";

const Message = ({ loading, message, to }) => {

  const theme = useTheme();

  const renderScore = (score) => {
    return [1,2,3,4,5].map(star => <S.Star src={star <= score ? StarFilled : StarEmpty } alt="Score"/>)
  }

  const formatText = (text, answer_type) => {

    if(answer_type === "DATE"){
      return format(
        parseISO(text), 
        "'Dia' dd 'de' MMMM 'de' yyyy",
        {locale: pt}
      );
    }

    return text;
  }

  return (
    <S.MessageContainer to={to}>
      <S.MessageWrapper to={to}>

        {loading && <S.LoadingWrapper><S.Loading src={theme.loading} alt="Loading"/></S.LoadingWrapper>}

        {message != null && message.answer_type !== "SCORE" && formatText(message.text, message.answer_type) }
        {message != null && message.answer_type === "SCORE" && renderScore(parseInt(message.text))}
        { message != null && <><br /><S.HourWrapper to={to}>{message.date}</S.HourWrapper></> }
      </S.MessageWrapper>
    </S.MessageContainer>
  );
}

export default Message;