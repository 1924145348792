import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect'

/* Types & Action Creators */

const { Types, Creators } = createActions({
    
    createConversation: ["user_identifier", "company_slug", "place_slug"],
    createConversationSuccess: ["id"],
    sendMessage: ["conversation", "answer", "in_response_to", "answer_type"],
    addMessage: ["message"],
    setLoading: ["loading"],
    setIntent: ["intent", "answer_type", "options"],
    setOptions: ["options"],
    clear: []
});

export const ChatTypes = Types;
export const ChatActions = Creators;

/* Selectors */

const selectLoading = createSelector(
  state => state.chat,
  chat => chat.loading
)

const selectConversation = createSelector(
  state => state.chat,
  chat => chat.conversation
)

const selectMessages = createSelector(
  state => state.chat,
  chat => chat.messages
)

const selectOptions = createSelector(
  state => state.chat,
  chat => chat.options
)

const selectIntent = createSelector(
  state => state.chat,
  chat => chat.intent
)

const selectAnswerType = createSelector(
  state => state.chat,
  chat => chat.answer_type
)

export const ChatSelectors = {
  selectLoading,
  selectConversation,
  selectMessages,
  selectOptions,
  selectIntent,
  selectAnswerType
}

/* Initial State */

export const INITIAL_STATE = Immutable({
    loading: false,
    conversation: null,
    intent: null, 
    answer_type: "END",
    messages: [],
    options: [],
});

/* Reducers */

export const addMessage = (state, { message }) =>
    state.setIn(["messages"], Immutable(state.messages).concat(message))

export const setLoading = (state, { loading }) =>
    state.merge({ loading })

export const setIntent = (state, { intent, answer_type, options }) =>
    state.merge({ intent, answer_type, options })

export const setOptions = (state, { options }) =>
    state.merge({ options })

export const createConversationSuccess = (state, { id }) =>
    state.merge({ conversation: id})

export const clear = (state) => INITIAL_STATE

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
    [Types.ADD_MESSAGE]: addMessage,
    [Types.SET_LOADING]: setLoading,
    [Types.SET_INTENT]: setIntent,
    [Types.SET_OPTIONS]: setOptions,
    [Types.CREATE_CONVERSATION_SUCCESS]: createConversationSuccess,
    [Types.CLEAR]: clear,
});

